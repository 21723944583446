// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-country-accommodation-tsx": () => import("./../../../src/templates/country/accommodation.tsx" /* webpackChunkName: "component---src-templates-country-accommodation-tsx" */),
  "component---src-templates-country-highlights-tsx": () => import("./../../../src/templates/country/highlights.tsx" /* webpackChunkName: "component---src-templates-country-highlights-tsx" */),
  "component---src-templates-country-index-tsx": () => import("./../../../src/templates/country/index.tsx" /* webpackChunkName: "component---src-templates-country-index-tsx" */),
  "component---src-templates-country-overview-tsx": () => import("./../../../src/templates/country/overview.tsx" /* webpackChunkName: "component---src-templates-country-overview-tsx" */),
  "component---src-templates-country-regions-tsx": () => import("./../../../src/templates/country/regions.tsx" /* webpackChunkName: "component---src-templates-country-regions-tsx" */),
  "component---src-templates-country-travel-info-tsx": () => import("./../../../src/templates/country/travel-info.tsx" /* webpackChunkName: "component---src-templates-country-travel-info-tsx" */),
  "component---src-templates-country-trips-previous-tsx": () => import("./../../../src/templates/country/trips-previous.tsx" /* webpackChunkName: "component---src-templates-country-trips-previous-tsx" */),
  "component---src-templates-country-trips-tsx": () => import("./../../../src/templates/country/trips.tsx" /* webpackChunkName: "component---src-templates-country-trips-tsx" */)
}

