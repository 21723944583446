import React from 'react';
import { SiteProvider } from './src/Context/site'

import './src/styles/app.css';
import './src/styles/tailwind.css'
import 'animate.css'

export const wrapRootElement = ( { element }) => (
  <SiteProvider>{element}</SiteProvider>
)
